var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-orders-container" },
    [
      _c("PageLayout", {
        scopedSlots: _vm._u([
          {
            key: "page-name",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "nav-title font-menu-large" },
                  _vm._l(_vm.navList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "nav-title-item",
                        class: { active: item.id === _vm.currentPage },
                        on: {
                          click: function ($event) {
                            return _vm.changePage(item.id)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.title) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "currency" },
                  _vm._l(_vm.currencyList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "currency-item",
                        class: {
                          active: _vm.currencyNum === index,
                          fadeOut: _vm.previouslySelected === index,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changeCurrency(index)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.value) + " ")]
                    )
                  }),
                  0
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "page-content",
            fn: function () {
              return [
                _c("div", { staticClass: "content" }, [
                  _c(
                    "div",
                    { staticClass: "packges-content" },
                    _vm._l(_vm.classTypes, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "class-item",
                          class: { fadeOutIn: _vm.fadeOutInPrices },
                        },
                        [
                          _c("div", { staticClass: "class-item-top" }, [
                            _c(
                              "div",
                              {
                                staticClass: "packages-level font-menu-medium",
                              },
                              [_vm._v(" " + _vm._s(item.level) + " CLASSES ")]
                            ),
                            _vm.currencyNum === 0 && index !== 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "packages-origin font-menu-medium",
                                  },
                                  [
                                    _c("span", [_vm._v("￥ ")]),
                                    _vm._v(_vm._s(item.origin) + " "),
                                  ]
                                )
                              : _vm._e(),
                            _vm.currencyNum === 1 && index !== 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "packages-origin font-menu-medium",
                                  },
                                  [
                                    _vm._v(" " + _vm._s(item.origin)),
                                    _c("span", [_vm._v(" €")]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.currencyNum === 2 && index !== 0
                              ? _c("div", { staticClass: "packages-origin" }, [
                                  _c("span", [_vm._v("$ ")]),
                                  _vm._v(_vm._s(item.origin) + " "),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "packages-amount font-menu-pricing-large",
                                class: [
                                  _vm.currencyNum === 0 || _vm.currencyNum === 2
                                    ? "currency_left"
                                    : "currency_right",
                                ],
                              },
                              [
                                _vm.currencyNum === 0
                                  ? _c("span", { staticClass: "symble" }, [
                                      _vm._v("￥ "),
                                    ])
                                  : _vm._e(),
                                _vm.currencyNum === 1
                                  ? _c("span", { staticClass: "symble eur" }, [
                                      _vm._v(" €"),
                                    ])
                                  : _vm._e(),
                                _vm.currencyNum === 2
                                  ? _c("span", { staticClass: "symble" }, [
                                      _vm._v("$ "),
                                    ])
                                  : _vm._e(),
                                _c("span", { staticClass: "current" }, [
                                  _vm._v(_vm._s(item.amount)),
                                ]),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "class-item-bottom" }, [
                            _c("div", { staticClass: "packages-single" }, [
                              _vm.currencyNum === 0
                                ? _c("span", { staticClass: "symble" }, [
                                    _vm._v("￥ "),
                                  ])
                                : _vm._e(),
                              _vm.currencyNum === 1
                                ? _c("span", { staticClass: "symble eur" }, [
                                    _vm._v(" €"),
                                  ])
                                : _vm._e(),
                              _vm.currencyNum === 2
                                ? _c("span", { staticClass: "symble" }, [
                                    _vm._v("$ "),
                                  ])
                                : _vm._e(),
                              _c(
                                "span",
                                { staticClass: "unit font-menu-pricing-small" },
                                [_vm._v(_vm._s(item.unitCost))]
                              ),
                              _c("span", { staticClass: "class" }, [
                                _vm._v("class"),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "tips" }, [
                    _c("div", { staticClass: "class-tip font-menu-small" }, [
                      _vm._v("* 1 class = 25 mintes"),
                    ]),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }