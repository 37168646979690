<template>
  <div class="my-orders-container">
    <PageLayout>
      <template #page-name>
        <div class="nav-title font-menu-large">
          <div
            class="nav-title-item"
            v-for="item in navList"
            :key="item.id"
            :class="{ active: item.id === currentPage }"
            @click="changePage(item.id)"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="currency">
          <div
            class="currency-item"
            :class="{
              active: currencyNum === index,
              fadeOut: previouslySelected === index,
            }"
            v-for="(item, index) in currencyList"
            :key="item.id"
            @click="changeCurrency(index)"
          >
            {{ item.value }}
          </div>
        </div>
      </template>

      <template #page-content>
        <div class="content">
          <div class="packges-content">
            <div
              class="class-item"
              :class="{ fadeOutIn: fadeOutInPrices }"
              v-for="(item, index) in classTypes"
              :key="index"
            >
              <div class="class-item-top">
                <div class="packages-level font-menu-medium">
                  {{ item.level }} CLASSES
                </div>
                <div
                  class="packages-origin font-menu-medium"
                  v-if="currencyNum === 0 && index !== 0"
                >
                  <span>￥ </span>{{ item.origin }}
                </div>

                <div
                  class="packages-origin font-menu-medium"
                  v-if="currencyNum === 1 && index !== 0"
                >
                  {{ item.origin }}<span> €</span>
                </div>

                <div
                  class="packages-origin"
                  v-if="currencyNum === 2 && index !== 0"
                >
                  <span>$ </span>{{ item.origin }}
                </div>
                <!-- <div class="packages-origin" v-if="index !== 0">
                  <span v-if="currencyNum === 0">￥</span>

                  <span v-else-if="currencyNum === 2">$</span>
                  {{ item.origin }}
                  <span v-else>€</span>
                </div> -->
                <div
                  class="packages-amount font-menu-pricing-large"
                  :class="[
                    currencyNum === 0 || currencyNum === 2
                      ? 'currency_left'
                      : 'currency_right',
                  ]"
                >
                  <span class="symble" v-if="currencyNum === 0">￥ </span>
                  <span class="symble eur" v-if="currencyNum === 1"> €</span>
                  <span class="symble" v-if="currencyNum === 2">$ </span>
                  <span class="current">{{ item.amount }}</span>
                </div>
              </div>
              <div class="class-item-bottom">
                <div class="packages-single">
                  <span class="symble" v-if="currencyNum === 0">￥ </span>
                  <span class="symble eur" v-if="currencyNum === 1"> €</span>
                  <span class="symble" v-if="currencyNum === 2">$ </span>
                  <span class="unit font-menu-pricing-small">{{
                    item.unitCost
                  }}</span>
                  <span class="class">class</span>
                </div>
              </div>
            </div>
          </div>
          <div class="tips">
            <div class="class-tip font-menu-small">* 1 class = 25 mintes</div>
          </div>
          <!-- 订课 -->
          <!-- <div class="purchase-button font-menu-medium">
            <div class="button">Purchase</div>
          </div> -->
        </div>
      </template>
    </PageLayout>
  </div>
</template>

<script>
import PageLayout from "@/components/Page/Layout/PageLayout.vue";
export default {
  name: "Prices",

  components: {
    PageLayout,
  },
  data() {
    return {
      currentPage: 1,
      navList: [
        {
          title: "Course Package",
          id: 1,
        },
      ],
      classTypes: [
        {
          level: "1-15",
          origin: "",
          amount: 2625,
          unitCost: 175,
        },
        {
          level: "16",
          origin: 2800,
          amount: 2400,
          unitCost: 150,
        },
        {
          level: "32",
          origin: 5600,
          amount: 4320,
          unitCost: 135,
        },
        {
          level: "64",
          origin: 11200,
          amount: 7680,
          unitCost: 120,
        },
        {
          level: "128",
          origin: 22400,
          amount: 13440,
          unitCost: 105,
        },
      ],
      currencyList: [
        {
          value: "￥ CNY",
          id: 0,
        },
        {
          value: "€ EUR",
          id: 1,
        },
        {
          value: "$ USD",
          id: 2,
        },
      ],
      currencyNum: 0,
      previouslySelected: null,
      fadeOutInPrices: "",
    };
  },
  methods: {
    changePage(index) {
      this.currentPage = index;
    },
    changeCurrency(index) {
      this.fadeOutInPrices = true;
      setTimeout(() => {
        this.previouslySelected = this.currencyNum;
        this.currencyNum = index;
      }, 100);
      setTimeout(() => {
        this.fadeOutInPrices = false;
      }, 400);
    },
  },
};
</script>

<style lang="scss" scoped>
.my-orders-container {
  font-weight: 600;
  .active {
    color: #224e96;
  }

  .nav-title {
    display: flex;

    .nav-title-item {
      cursor: default;
      // margin-right: 5%;
      &:not(.active) {
        cursor: pointer;
      }
      a {
        color: #9e9e9f;
      }
    }
  }
  .currency {
    width: 25%;
    float: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    // padding: 1% 2%;
    margin-bottom: 2%;
    border-radius: 30px;
    font-size: 0.8rem;
    color: #224e96;

    .currency-item {
      text-align: center;
      background: #fff;
      border-radius: 30px;
      padding: 4% 8%;
      cursor: pointer;
      font-weight: 100;

      &:hover:not(.active) {
        background: #cd4b3f56 !important;
        color: #fff;
      }
    }
    .active {
      background: #cd4c3f;
      color: #fff;
    }
  }
  .content {
    width: 100%;
    // height: 100%;
    // height: 70vh;
    // background: #fff;
    background-color: #fff;
    border-radius: 20px;
    padding: 2%;
    box-sizing: border-box;
    cursor: default;
    .packges-content {
      display: flex;
      justify-content: space-between;
      border-radius: 20px;
      // align-items: center;
      margin-bottom: 2%;
      .class-item {
        background: #fafafa;
        width: 19%;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .symble {
          font-size: 0.9rem;
          line-height: 1rem;
          position: absolute;
          top: 0;
          left: 0;
        }
        .class-item-top {
          padding: 15% 10% 8% 15%;
          // background: #fafafa;

          .symble {
            font-size: 0.9rem;
            line-height: 0.9rem;
          }
        }
        .class-item-bottom {
          display: flex;
          color: #fff;
          padding: 8% 15% 5% 10%;
          background: #224e96;
          border-bottom-right-radius: 15px;
          border-bottom-left-radius: 15px;
          justify-content: flex-end;

          .class {
            font-size: 0.9rem;
            line-height: 1rem;
            margin-left: 8%;
          }
          .packages-single {
            position: relative;
            line-height: 1.9rem;

            .symble {
              left: -13%;
            }
          }
        }
        .packages-level {
          color: #cd4c3f;
          margin-bottom: 2%;
        }
        .packages-origin {
          color: #9e9e9f;
          text-decoration: line-through;
          margin-bottom: 3.5%;
        }
        .packages-amount {
          color: #224e96;
          position: relative;
          line-height: 1.9rem;
          display: inline-block;
          width: auto;

          &.currency_left {
            margin-left: 10%;
            .symble {
              text-align: right;
              left: -13px;
            }
          }
          &.currency_right {
            margin-left: 10%;
            .symble {
              text-align: right;
              right: -13px;
            }
          }
        }
      }
      .eur {
        left: 65% !important;
        top: -15% !important;
      }
    }
    .purchase-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 3%;

      .button {
        line-height: 1rem;
        // width: 15%;
        color: #fff;
        background: #cd4c3f;
        cursor: pointer;
        padding: 1.7% 5%;
        border-radius: 30px;

        &:hover {
          opacity: 0.8;
        }
      }
    }
    .tips {
      .class-tip {
        display: flex;
        justify-content: flex-end;
        margin-right: 1%;
        color: #224e96;
        line-height: 1rem;
        margin-bottom: 5%;
      }
    }
  }
  /deep/ .page-content {
    padding-top: 3%;
    // padding: 0;
    // border-radius: 0;
    // margin: 0;
    // width: 100%;
  }
}
</style>
<style lang="scss">
.fadeOut {
  animation-name: fadeOut;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.fadeOutIn {
  animation-name: fadeOutAndIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@-webkit-keyframes fadeOut {
  from {
    background-color: rgba(205, 76, 63, 1);
  }
  to {
    background-color: rgba(205, 76, 63, 0);
  }
}
@keyframes fadeOut {
  from {
    background-color: rgba(205, 76, 63, 1);
  }
  to {
    background-color: rgba(205, 76, 63, 0);
  }
}

@-webkit-keyframes fadeOutAndIn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOutAndIn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
