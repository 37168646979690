<template>
  <div class="page-layout-container">
    <div class="page-name">
      <slot name="page-name"></slot>
    </div>
    <div class="page-content" ref="contentBox">
      <slot name="page-content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contentBox: null,
    };
  },
  name: "PageLayout",
  mounted() {
    this.contentBox = this.$refs.contentBox;
    this.$bus.$on("scrollToBottom", () => {
      // console.log("++++++++++++++++");
      const scrollHeight = this.contentBox.scrollHeight;
      // console.log(scrollHeight, this.$refs.contentBox.scrollTop);
      this.contentBox.scrollTop = scrollHeight;
      // console.log(this.$refs.contentBox.scrollTop);
    });
  },
};
</script>

<style lang="scss" scoped>
.page-layout-container {
  width: 100%;
  height: 98%;
  margin-top: 2%;

  .page-content {
    // border-radius: 10px;
    width: 100%;
    // height: 65%;
    // max-height: 75%;
    overflow-y: auto;
    // background: #ffffff;
    padding: 0;
    margin-top: 4%;

    // border-radius: 24px;
  }
  ::-webkit-scrollbar-track {
    background: #efefef;
    border-radius: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-corner {
    background: #179a16;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    // display: none;
  }
}
</style>


